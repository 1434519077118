hr {
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
}

.container-box {
  width: 80%;
  background-color: transparent;
  position: relative;
}

.particles-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.profileItems {
  display: inline-block;
}

.rowForProjects {
  position: relative;
  display: flex;
  flex-flow: row;
  justify-content: center;
  flex-wrap: wrap;
  z-index:11
}

.styles_overlay-close__3e1ke{
  color: white;
}

h1,
h2
 {
  font-weight: 700;
  font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
