.custom-img {
  height: 100vh;
  object-fit: cover;
}

@media screen and (min-width: 360px) and (max-width: 550px) {
  .custom-img {
    height: 130vh;
    object-fit: cover;
  }
}

@media screen and (max-width: 360px) {
  .custom-img {
    height: 160vh;
    object-fit: cover;
  }
}


.picCarousal {
  position: absolute;
    left: 20%;
    top: 12vh;
    right: 20%;
}

.picCarousal #container {
  position: relative;
  width: 250px;
  /* top: 25%; */
  /* left: 650px; */
  height: 250px;
  border-radius: 50%;
  /* animation: blinker 1s cubic-bezier(0.5, 0, 1, 1) infinite alternate; */
  z-index: 1;
}

#home{
  position:relative;
}
