#container {
    position: relative;
    width: 400px;
    /* top: 25%; */
    /* left: 650px; */
    height: 400px;
    border-radius: 50%;
    /* animation: blinker 1s cubic-bezier(0.5, 0, 1, 1) infinite alternate; */
    z-index: 1;
}

@media screen and (max-width: 500px) {

    #container {
        position: relative;
        width: 300px;
        /* top: 25%; */
        /* left: 650px; */
        height: 300px;
        border-radius: 50%;
        /* animation: blinker 1s cubic-bezier(0.5, 0, 1, 1) infinite alternate; */
        z-index: 1;
    }
}

 #circle {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    /* border: 6px solid rgb(255, 255, 255); */
    border-radius: 50%;
 }
 

.img .logo {
    margin-top: 2.5%;
    margin-left: 2.5%;
    position: absolute;
    width: 95%;
    height: 95%;
    text-align: center;
    background-color: rgb(211, 211, 238);
    border-radius: 50%;
    /* animation: blinker 1s cubic-bezier(0.5, 0, 1, 1) infinite alternate; */
    z-index: 1;
}
  /* .Blink h1{
      text-align: right;
  } */


/* 

.img .logo {
    position: absolute;
    width: 300px;
    top: 80px;
    left: 700px;
    height: 300px;
    text-align: center;
    background-color: rgb(211, 211, 238);
    border-radius: 50%;
    z-index: 1;
} */

